import React from 'react'
import "./Programs.css"
import { programsData } from '../../data/programsData'; //gat data from here
import RightArrow from "../../assets/rightArrow.png";
const Programs = () => {
  return (
    <div className="Programs" id="Programs">
      {/*header*/}
      <div className="programs-header">
        <span className="stroke-text">Explore our</span>
        <span>Programs</span>
        <span className="stroke-text">To shape you</span>
      </div>
    
    <div className='prgoram-categories'>
        {programsData.map((program,id)=>(
            <div className='category'>
                {program.image}
                <span>{program.heading}</span>
                <span>{program.details}</span>
                <div className="join-now">
                    <span>Join Now</span>
                    <img src={RightArrow} />
                </div>
            </div>
        ))}
      </div>

    </div>
  );
}

export default Programs