import React, {useRef} from 'react'
import "./Join.css";
import emailjs from "@emailjs/browser"


const Join = () => {

    const form = useRef()

    const sendEmail = (e) => { //come from:- https://www.emailjs.com/docs/examples/reactjs/
        e.preventDefault();
    
        emailjs.sendForm('service_149q3qe', 'template_gsfhxeo', form.current, 'APvZE00aJaLfaiSbl')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };

  return (
    <div className="Join" id="join-us">
      <div className="left-j">
        <hr />
        <div>
          <span className="stroke-text">Ready to </span>
          Level up your body
          <span className="stroke-text"> with us?</span>
        </div>
        <div>
          
        </div>
      </div>
      
        <div className="right-j">
          <form ref={form} className='email-container' onSubmit={sendEmail}>
              <input type="email" name="user_email"  placeholder='Enter your email address'/>
              {/*input name  must same as Emailjs tamplate*/}
              <button className='btn btn-j'>Join Now</button>
          </form>
        </div>
      
      
    </div>
  );
}

export default Join