import React from 'react'
import Header from '../Header/Header'
import './Hero.css'
import hero_image from "../../assets/hero_image.png";
import hero_image_back from "../../assets/hero_image_back.png";
import Heart from "../../assets/heart.png";
import calories from "../../assets/calories.png";
import {motion} from 'framer-motion'; //use to animation
//any div that we animate simply write it as motion.div
import NumberCounter from "number-counter"

const Hero = () => {

  const transition ={type: 'spring', duration:3}
  return (
    <div className="hero" id="home">
      <div className='blur hero-blur'></div>
      <div className="col-12  col-xl-8 left-h">
        <Header />
        <div className="the-best-ad">
          <motion.div
            initial={{left:'238px'}}
            whileInView={{left:'8px'}}
            transition={{...transition, type:'tween'}}> {/*using sprad to add tween type in exsiting type*/}
          </motion.div>
          
          <span>THE BEST FITNESS CLUB IN THE TOWN</span>
        </div>
        {/*Hero Heading */}
        <div className="hero-text">
          <div>
            <span className="stroke-text">Shape </span>
            <span>Your</span>
          </div>
          <div>
            <span>Ideal body</span>
          </div>
          <div>
            <span>
              In here we will help you to shape and build your ideal body and
              live up your life to fullest
            </span>
          </div>
        </div>
        {/*counter section*/}
        <div className="figures">
          <div>
            <span>
              <NumberCounter end={140} start={100} delay="4" preFix="+" />
            </span>
            <span>expert coaches</span>
          </div>
          <div>
            <span>
            <NumberCounter end={978} start={800} delay="4" preFix="+" />
            </span>
            <span>Members joined</span>
          </div>
          <div>
            <span>
            <NumberCounter end={50} start={0} delay="4" preFix="+" />
            </span>
            <span>fitness programs</span>
          </div>
        </div>
        {/*top section btn*/}
        <div className="hero-buttons">
          <button className="">Get Started</button>
          <button className="">Learn More</button>
        </div>
      </div>
      {/*right section*/}
      <div className="col-12  col-xl-4 right-h">
        {/* <button className="btn btn-joinnow">
          <a href="/">Join Now</a>
        </button> */}

        <motion.div 
          initial ={{right:"-1em"}}
          whileInView={{right:"4em"}}
          transition={transition}
          className="heart-rate">

          <img src={Heart} alt="Heart" />
          <span>Heart Rate</span>
          <span>116 bpm</span>
        </motion.div>
        {/*for small device heart rate start*/}
        <div className="heart-rate small_device">
          <img src={Heart} alt="Heart" />
          <div>
            <span>Heart Rate</span>
            <span>116 bpm</span>
          </div>
        </div>
        {/*for small device heart rate start*/}

        <img className="hero-image" src={hero_image} alt="" />
        <motion.img 
        initial={{right:'11em'}}
        whileInView={{right:'20em'}}
        transition={transition}
        className="hero-image-back" src={hero_image_back} alt="" />
        <motion.div
          initial={{right:"37em"}}
          whileInView={{right: "28em"}}
          transition={transition}
          className="calories">

          <img src={calories} alt="" />
          <div>
            <span>Calories burned</span>
            <span>220 kcal</span>
          </div>
        </motion.div>

      </div>
    </div>
  );
}

export default Hero