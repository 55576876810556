import React from 'react'
import "./plans.css"
import { plansData } from '../../data/plansData';
import whiteTick from '../../assets/whiteTick.png';
const Plans = () => {
  return (
    <div className="plans-container" id="Plans">
      <div className='blur plans-blur-1'></div>
      <div className='blur plans-blur-2'></div>

      <div className="programs-header plans-header">
        <span className="stroke-text">Ready to Start</span>
        <span>Your Journey</span>
        <span className="stroke-text">now withus</span>
      </div>
      {/* plams card */}  
        <div className='plans'>
            {plansData.map((plan, i)=>(
                <div className="plan" key={i}>
                    {plan.icon}
                    <span>{plan.name}</span>
                    <span>{plan.price}</span>

                    <div className="features">
                        {plan.features.map((feature, i)=>(
                            <div className="feature">
                                <img src={whiteTick} />
                                <span key={i}>{feature}</span> 
                            </div>
            ))}
                    </div>
                    <div>
                        <span>See more benefits </span>
                    </div>
                    <button className='btn btn-join'>Join Now</button>
                </div>
            ))}
        </div>
        
    </div>
  );
}

export default Plans