import React from 'react';
import "./Header.css"
import Logo from "../../assets/logo.png";
import { Link } from 'react-scroll';
import bars from "../../assets/bars.png"
import { useState } from 'react';
const Header = () =>{

    function test() {
     var testy = document.getElementsByClassName('btn-joinnow').childNodes.length
    
    console.log(testy); 
    }

    const [menuOpened, setmenuOpened] = useState(false);
   
    return(
        <div className="header_upper nav_fixed-top">

        <div className="header">
            <img src={Logo} alt="logo" className='logo'/>
            <ul className='header-menu'>
                <li><Link to="home">Home</Link></li>
                <li><Link to="Programs">Programs</Link></li>            
                <li><Link to="reasons">Why us</Link></li>            
                <li><Link to='Plans'>Plans</Link></li>            
                <li><Link to='testimonials'>Testimonials</Link></li>
            </ul>
            <div className="menu_small_main">
            
                <img src={bars} onClick={()=>setmenuOpened(!menuOpened)} />
                {(menuOpened === true) ? (
                    <ul className='small_header-menu'>
                            <li><Link to="home" onClick={()=>setmenuOpened(false)}>Home</Link></li>
                            <li><Link to="Programs" onClick={()=>setmenuOpened(false)}>Programs</Link></li>            
                            <li><Link to="reasons" onClick={()=>setmenuOpened(false)}>Why us</Link></li>            
                            <li><Link to='Plans' onClick={()=>setmenuOpened(false)}>Plans</Link></li>            
                            <li><Link to='testimonials' onClick={()=>setmenuOpened(false)}>Testimonials</Link></li>
                    </ul>
                ): null }
                
                <div>
                        
                    </div>
            </div>            
        </div>
        <div className="join-now_btn_div">
            <button className="btn btn-joinnow">
            <a href="/">Join Now</a>
            </button>
        </div>
        </div>
    )
}
export default Header;

